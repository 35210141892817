.App {
  text-align: center;
}

body, html {
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.navbar{
  width: 100%
}

.results{
  padding: 10px;
}

.upperspace{
  margin-top: 40px;
}

.smallSpace{
  margin: 100px;
}

.menuspace{
  margin: 15px;
}

.css-1kfwsuu-resultStats{
  color: purple;
  font-size: 72px;
}

.stylestats{
  font-size: 72px;
}

.statistics{
  color: black;
  padding: 10px;
  border-width: 1px;
  border-color: lightgrey;
  border-style: solid;
}

nav li a.active, .nav-active {
  font-weight: bold;
}

.searchPopover {
  max-width: 500px !important;
}
.searchPopover img {
  max-width: 100%;
}

.scroll-shadows {
  background: 
    linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
    linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 15px, 100% 15px, 100% 5px, 100% 5px;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #000000;
  border-bottom-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  animation: spinner-rotation 0.3s linear infinite;
}

.spinner.spinner-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.spinner.spinner-inline {
  display: inline-block;
}

@keyframes spinner-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
